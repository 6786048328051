import { React, useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import current from "../Images/current.png";
import axios from "axios";
import TrafficChart from "./TrafficChart";
import NoBookings from "../Images/no-notifications-hfkUZp4KLR.png";
import "../Styles/Settings.css";
import "../Styles/Bookings.css";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { handleApiError } from "./apiErrorHandling";
import { DatePicker } from "rsuite";
import SlideOver from "./SlideOver";
import { useEstateCode } from "./EstateCodeContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

const Dashboard = ({ children }) => {
  const { estateCode } = useEstateCode();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingsPerPage] = useState(10);
  const [filteredBookings2, setFilteredBookings2] = useState([]);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [outletCurrency, setOutletCurrency] = useState("");
  const formatDateString = (date) => {
    return date.toISOString().split("T")[0];
  };
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (selectedDate === null) {
      setSelectedDate(new Date());
    } else {
      setSelectedDate(selectedDate);
    }
  }, [selectedDate]);

  const [categoryOptions2, setCategoryOptions2] = useState([]);
  const fetchBookings = async () => {
    try {
      const date = formatDateString(selectedDate);
      const values = [1, 3, 4, 5];
      const bookings = [];

      for (const value of values) {
        try {
          const url = getApiUrl(
            `/api/v2/merchant/bookings/viewDateFilteredBooking/${estateCode}/b/${value}?startDate=${date}`
          );
          const response = await axios.get(url, {
            withCredentials: true,
          });

          console.log("Response:", response);

          if (response.status === 200) {
            const data = response.data.data;
            const bookingsData = Object.values(data).filter(
              (item) => typeof item === "object" && item.reservation_code
            );

            bookings.push(...bookingsData);
            const categories = response.data.data.packageCategory.map(
              (category) => ({
                value: category.package_category_name,
                label: category.package_category_name,
              })
            );
            setCategoryOptions2(categories);
          } else if (response.status === 204) {
            console.log(`No content for value ${value}`);
          } else {
            console.warn(
              `Unexpected status code for value ${value}: ${response.status}`
            );
          }
        } catch (error) {
          console.error(`Error fetching data for value ${value}:`, error);
        }
      }

      setBookings(bookings);
      setLoading(false);
    } catch (error) {
      handleApiError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, [selectedDate, estateCode]);

  // filter functionality ///

  const [isCategoryOpen2, setIsCategoryOpen2] = useState(false);
  const [selectedCategory2, setSelectedCategory2] = useState(null);
  const [displayedCategory, setDisplayedCategory2] = useState("Category");

  const handleSelectedCategory2 = (category) => {
    if (category === null) {
      setSelectedCategory2(null);
      setDisplayedCategory2("Category");
      setIsCategoryOpen2(false);
    } else {
      setSelectedCategory2(category);
      setDisplayedCategory2(
        categoryOptions2.find((option) => option.value === category)?.label ||
          "Category"
      );
      setIsCategoryOpen2(false);
    }
  };

  const [selectedPackage2, setSelectedPackage2] = useState(null);
  const [displayedPackage, setDisplayedPackage2] = useState("Package");
  const [isPackageOpen2, setIsPackageOpen2] = useState(false);
  const packageOptions2 = [
    { value: "Regular", label: "Regular" },
    { value: "Premium", label: "Premium" },
    { value: "Exclusive", label: "Exclusive" },
    { value: "Ultra Exclusive", label: "Ultra Exclusive" },
  ];

  const handleSelectedPackage2 = (packageValue) => {
    if (packageValue === null) {
      setSelectedPackage2(null);
      setDisplayedPackage2("Package");
      setIsPackageOpen2(false);
    } else {
      setSelectedPackage2(packageValue);
      setDisplayedPackage2(
        packageOptions2.find((option) => option.value === packageValue).label
      );
      setIsPackageOpen2(false);
    }
  };

  useEffect(() => {
    const filteredByCategory = selectedCategory2
      ? bookings.filter(
          (booking) => booking.bookingPackageCategory === selectedCategory2
        )
      : bookings;

    const filteredByPackage = selectedPackage2
      ? filteredByCategory.filter(
          (booking) =>
            booking.bookingGrouplePackageCategory === selectedPackage2
        )
      : filteredByCategory;

    let filteredBookings2 = filteredByPackage;

    if (searchQuery2.trim() !== "") {
      const searchWords = searchQuery2.trim().toLowerCase();
      filteredBookings2 = filteredBookings2.filter((booking) => {
        const fullName =
          `${booking?.bookerFirstName} ${booking?.bookerLastName}`.toLowerCase();
        return (
          booking?.reservation_code?.toLowerCase().includes(searchWords) ||
          fullName.includes(searchWords)
        );
      });
    }

    setFilteredBookings2(filteredBookings2);
    setCurrentBookings2(filteredBookings2);
  }, [selectedCategory2, selectedPackage2, searchQuery2, bookings]);

  useEffect(() => {
    const searchWords = searchQuery2.trim().toLowerCase();
    if (bookings && bookings.length > 0) {
      const filtered = bookings.filter((booking) => {
        const fullName =
          `${booking?.bookerFirstName} ${booking?.bookerLastName}`.toLowerCase();
        return (
          booking?.reservation_code?.toLowerCase().includes(searchWords) ||
          fullName.includes(searchWords)
        );
      });
      setCurrentBookings2(filtered);
    } else {
      setCurrentBookings2([]);
    }
  }, [searchQuery2, filteredBookings2]);

  useEffect(() => {
    const storedPackage2 = localStorage.getItem("selectedPackage2");
    const storedCategory2 = localStorage.getItem("selectedCategory2");
    const storedSearchQuery2 = localStorage.getItem("searchQuery2");

    if (storedPackage2) setSelectedPackage2(JSON.parse(storedPackage2));
    if (storedCategory2) setSelectedCategory2(JSON.parse(storedCategory2));
    if (storedSearchQuery2) setSearchQuery2(storedSearchQuery2);
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedPackage2", JSON.stringify(selectedPackage2));
    localStorage.setItem(
      "selectedCategory2",
      JSON.stringify(selectedCategory2)
    );
    localStorage.setItem("searchQuery2", searchQuery2);
  }, [selectedPackage2, selectedCategory2, searchQuery2, selectedDate]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const [currentBookings2, setCurrentBookings2] = useState([]);

  useEffect(() => {
    const sortedFilteredBookings = [...filteredBookings2].sort((a, b) => {
      const getTime = (timeString) => {
        const [time, period] = timeString.split(" ");
        const [hours, minutes] = time.split(":").map(Number);
        if (period === "AM" && hours === 12) {
          return 24 * 60;
        }
        return period === "AM"
          ? hours * 60 + minutes
          : (hours === 12 ? 0 : hours + 12) * 60 + minutes;
      };

      const startTimeA = getTime(a.bookingSlotStartTime);
      const startTimeB = getTime(b.bookingSlotStartTime);

      return startTimeA - startTimeB;
    });

    const slicedBookings = sortedFilteredBookings.slice(
      indexOfFirstBooking,
      indexOfLastBooking
    );
    setCurrentBookings2(slicedBookings);
  }, [filteredBookings2, indexOfFirstBooking, indexOfLastBooking]);

  const fetchStats = async () => {
    try {
      const url = getApiUrl(
        `/api/v2/merchant/bookings/viewAnalyticDashboardMetric/${estateCode}`
      );
      const response = await axios.get(url, { withCredentials: true });

      switch (response.status) {
        case 200:
          const responseData = response.data.data;
          setOutletCurrency(responseData.currency);
          const updatedStats = [
            {
              id: 1,
              name: "Total Revenue",
              stat: responseData.dashboardRevenueAnalysis
                ? responseData.dashboardRevenueAnalysis.current_month_revenue
                : "1",
              icon: UsersIcon,
              change: responseData.dashboardRevenueAnalysis
                ? responseData.dashboardRevenueAnalysis
                    .revenue_change_percentage + "%"
                : "1%",
              changeType: responseData.dashboardRevenueAnalysis
                ? responseData.dashboardRevenueAnalysis
                    .revenue_change_percentage >= 0
                  ? "increase"
                  : "decrease"
                : "increase",
            },
            {
              id: 2,
              name: "Total Bookings",
              stat: responseData.dashboardBookingAnalysis
                ? responseData.dashboardBookingAnalysis.current_month_booking
                : "1",
              icon: EnvelopeOpenIcon,
              change: responseData.dashboardBookingAnalysis
                ? responseData.dashboardBookingAnalysis
                    .booking_change_percentage + "%"
                : "1%",
              changeType: responseData.dashboardBookingAnalysis
                ? responseData.dashboardBookingAnalysis
                    .booking_change_percentage >= 0
                  ? "increase"
                  : "decrease"
                : "increase",
            },
            {
              id: 3,
              name: "Average per spent",
              stat: responseData.dashboardAveragePerSpent
                ? responseData.dashboardAveragePerSpent
                    .current_month_avg_per_spent
                : "1",
              icon: CursorArrowRaysIcon,
              change: responseData.dashboardAveragePerSpent
                ? responseData.dashboardAveragePerSpent
                    .avg_per_spent_change_percentage + "%"
                : "1%",
              changeType: responseData.dashboardAveragePerSpent
                ? responseData.dashboardAveragePerSpent
                    .avg_per_spent_change_percentage >= 0
                  ? "increase"
                  : "decrease"
                : "increase",
            },
            {
              id: 4,
              name: "Average group size",
              stat: responseData.dashboardAverageGroupSize
                ? responseData.dashboardAverageGroupSize
                    .current_month_avg_group_size
                : "1",
              icon: CursorArrowRaysIcon,
              change: responseData.dashboardAverageGroupSize
                ? responseData.dashboardAverageGroupSize
                    .avg_group_size_change_percentage + "%"
                : "1%",
              changeType: responseData.dashboardAverageGroupSize
                ? responseData.dashboardAverageGroupSize
                    .avg_group_size_change_percentage >= 0
                  ? "increase"
                  : "decrease"
                : "increase",
            },
          ];
          setStats(updatedStats);
          break;
        case 204:
          setStats([]);
          break;
        default:
          console.warn(`Unexpected status code: ${response.status}`);
          setStats(null);
          break;
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [estateCode]);

  const [selectedBooking, setSelectedBooking] = useState(null);
  const [open, setOpen] = useState(true);
  const [selectedSpoc2, setSelectedSpoc2] = useState("");

  const fetchBookingDetails = async (reservationCode) => {
    try {
      const url = getApiUrl(
        `/api/v1/merchant/bookings/viewBookings/${reservationCode}`
      );
      const response = await axios.get(url, {
        withCredentials: true,
      });

      setSelectedBooking(response.data.data);
      setSelectedSpoc2(response.data.data[0].spocName);

      // console.log(selectedBooking);
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  const categoryDropdownRef = useRef(null);
  const packageDropdownRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      categoryDropdownRef.current &&
      !categoryDropdownRef.current.contains(event.target)
    ) {
      setIsCategoryOpen2(false);
    }
    if (
      packageDropdownRef.current &&
      !packageDropdownRef.current.contains(event.target)
    ) {
      setIsPackageOpen2(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="bg-lightBlue pt-5  min-h-90">
        <div>
          <dl className=" px-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            {stats !== null ? (
              stats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex items-baseline pb-7">
                    <p className="text-2xl font-bold text-black">
                      {(item.name === "Total Revenue" ||
                        item.name === "Average per spent") &&
                      item.stat ? (
                        <div>
                          <span className="text-xl font-medium">
                            {outletCurrency}
                          </span>{" "}
                          {parseInt(item.stat).toLocaleString()}
                        </div>
                      ) : (
                        <div>{parseInt(item.stat).toLocaleString()}</div>
                      )}
                    </p>

                    <div className="absolute w-full flex inset-x-0 bottom-0 px-4 py-4 sm:px-6">
                      <div
                        className="w-full flex-col gap-2 "
                        style={{ alignItems: "center" }}
                      >
                        <p
                          className={classNames(
                            item.changeType === "increase"
                              ? "bg-green-100 text-green-800"
                              : "bg-red-100 text-red-800",
                            "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-xs whitespace-nowrap font-medium md:mt-2 lg:mt-0 gap-1"
                          )}
                          style={{ alignItems: "center" }}
                        >
                          {item.changeType === "increase" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                            >
                              <path
                                d="M11.099 10.4286C10.998 10.604 10.8522 10.7493 10.6765 10.8497C10.5008 10.9501 10.3015 11.002 10.0992 11H1.90071C1.69835 11.002 1.49911 10.9501 1.32341 10.8497C1.14771 10.7493 1.00188 10.604 0.900869 10.4286C0.802308 10.2599 0.750366 10.0681 0.750366 9.87269C0.750366 9.67731 0.802308 9.48544 0.900869 9.31675L4.99915 2.19784C5.10159 2.02343 5.24782 1.87882 5.42337 1.77834C5.59891 1.67786 5.79766 1.625 5.99993 1.625C6.2022 1.625 6.40095 1.67786 6.5765 1.77834C6.75204 1.87882 6.89828 2.02343 7.00071 2.19784L11.0999 9.31675C11.1984 9.48553 11.2501 9.67744 11.25 9.87281C11.2498 10.0682 11.1977 10.26 11.099 10.4286Z"
                                fill="#2EB265"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                            >
                              <path
                                d="M0.900884 2.57137C1.00189 2.39602 1.14772 2.25068 1.32342 2.15027C1.49912 2.04986 1.69837 1.99798 1.90073 1.99997L10.0992 1.99997C10.3015 1.99798 10.5008 2.04986 10.6765 2.15027C10.8522 2.25068 10.998 2.39602 11.099 2.57137C11.1976 2.74007 11.2495 2.93194 11.2495 3.12731C11.2495 3.32269 11.1976 3.51456 11.099 3.68325L7.00073 10.8022C6.89829 10.9766 6.75206 11.1212 6.57651 11.2217C6.40097 11.3221 6.20221 11.375 5.99995 11.375C5.79768 11.375 5.59892 11.3221 5.42338 11.2217C5.24784 11.1212 5.1016 10.9766 4.99917 10.8022L0.899946 3.68325C0.801527 3.51447 0.749747 3.32256 0.749912 3.12719C0.750077 2.93181 0.80218 2.73999 0.900884 2.57137Z"
                                fill="#EF4722"
                              />
                            </svg>
                          )}
                          <span className="sr-only">
                            {" "}
                            {item.changeType === "increase"
                              ? "Increased"
                              : "Decreased"}{" "}
                            by{" "}
                          </span>
                          {parseInt(item.change).toLocaleString()} %
                        </p>
                        <p className=" font-normal text-sm text-neutral-950">
                          from last month
                        </p>
                      </div>
                    </div>
                  </dd>
                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </dl>
        </div>

        <div className="flex w-full gap-4 px-4  ">
          <div className="w-full">
            {/* search function */}
            <div className="bg-lightBlue pt-5 flex justify-between">
              <div className="flex gap-2">
                <div style={{ width: "53%" }}>
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative text-gray-400 focus-within:text-gray-300">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search"
                      className="block w-full rounded-md border border-gray-300 py-2.5 pl-10 pr-3 text-gray-900 sm:text-sm sm:leading-6 focus:border-none focus:color-white"
                      placeholder="Search by booking ID, customer name"
                      type="search"
                      name="search"
                      value={searchQuery2}
                      onChange={(e) => setSearchQuery2(e.target.value)}
                    />
                  </div>
                </div>

                {/* filter button  */}
                <div
                  className="sort-wrapper-main"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsPackageOpen2(false);
                  }}
                  ref={categoryDropdownRef}
                >
                  <div className="custom-dropdown">
                    <div
                      className={`dropdown-header ${
                        selectedCategory2 ? "active" : ""
                      }`}
                      onClick={() => {
                        setIsCategoryOpen2(!isCategoryOpen2);
                      }}
                    >
                      {selectedCategory2 ? (
                        <div className="Time-placeHolder">
                          <div>
                            <div className="Time-Value">Category</div>
                          </div>
                          <div>
                            <ChevronDownIcon
                              className={`chevron-icon ${
                                isCategoryOpen2 ? "up" : "down"
                              }`}
                              style={{
                                transform: isCategoryOpen2
                                  ? "rotate(180deg)"
                                  : "none",
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="Time-placeHolder">
                          <div className="Time-placeholder-child text-gray-500">
                            Category
                          </div>
                          <div>
                            <ChevronDownIcon
                              className={`chevron-icon ${
                                isCategoryOpen2 ? "up" : "down"
                              }`}
                              style={{
                                transform: isCategoryOpen2
                                  ? "rotate(180deg)"
                                  : "none",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {isCategoryOpen2 && (
                      <div className="dropdown-packageOptions">
                        {categoryOptions2.map((option) => (
                          <div
                            key={option.value}
                            style={{ borderRadius: "0.25rem" }}
                            className={`dropdown-option flex justify-between hover:bg-customLilac hover:text-groupleBlue ${
                              selectedCategory2 === option.value
                                ? "selected"
                                : ""
                            }`}
                            onClick={() =>
                              handleSelectedCategory2(option.value)
                            }
                          >
                            {option.label}
                            {selectedCategory2 === option.value && (
                              <button
                                className="clear-filter-button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSelectedCategory2(null);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="sort-wrapper-main"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsCategoryOpen2(false);
                  }}
                  ref={packageDropdownRef}
                >
                  <div
                    className={`dropdown-header ${
                      selectedPackage2 ? "active" : ""
                    }`}
                    onClick={() => {
                      setIsPackageOpen2(!isPackageOpen2);
                    }}
                  >
                    {selectedPackage2 ? (
                      <div className="Time-placeHolder">
                        <div>
                          <div className="Time-Value">Package</div>
                        </div>
                        <div>
                          <ChevronDownIcon
                            className={`chevron-icon ${
                              isPackageOpen2 ? "up" : "down"
                            }`}
                            style={{
                              transform: isPackageOpen2
                                ? "rotate(180deg)"
                                : "none",
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="Time-placeHolder">
                        <div className="Time-placeholder-child text-gray-500">
                          Package
                        </div>
                        <div>
                          <ChevronDownIcon
                            className={`chevron-icon ${
                              isPackageOpen2 ? "up" : "down"
                            }`}
                            style={{
                              transform: isPackageOpen2
                                ? "rotate(180deg)"
                                : "none",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {isPackageOpen2 && (
                    <div className="dropdown-packageOptions">
                      {packageOptions2.map((option) => (
                        <div
                          key={option.value}
                          style={{ borderRadius: "0.25rem" }}
                          className={`dropdown-option flex justify-between hover:bg-customLilac hover:text-groupleBlue ${
                            selectedPackage2 === option.value ? "selected" : ""
                          }`}
                          onClick={() => handleSelectedPackage2(option.value)}
                        >
                          {option.label}
                          {selectedPackage2 === option.value && (
                            <button
                              className="clear-filter-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSelectedPackage2(null);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18 18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <DatePicker
                placeholder="Select a date"
                oneTap
                format="dd-MMM-yyyy"
                size="lg"
                value={selectedDate}
                onChange={handleDateChange}
                renderExtraFooter={() => null}
                cleanable={false}
                style={{ width: "25%", marginLeft: "0.7rem" }}
                className="custom-date-range-picker2"
              />
            </div>

            {/* bookings table  */}
            <div className="mt-4  border border-gray-300  rounded-tr-xl rounded-tl-xl h-fit ">
              <div className="flow-root overflow-hidden rounded-tr-xl rounded-tl-xl h-fit ">
                <div className="mx-auto w-full">
                  {loading ? (
                    <div>Loading...</div>
                  ) : filteredBookings2.length === 0 ? (
                    <div className="noBookingWrapper">
                      <img src={NoBookings} alt="No Bookings" />
                      <h2>No Booking</h2>
                      <h3>You don’t have any upcoming booking for today</h3>
                    </div>
                  ) : (
                    <table className="w-full text-left ">
                      <thead className="bg-lightGrey w-full rounded-tr-xl rounded-tl-xl ">
                        <tr className="border-b px-10 ">
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-gray-500"
                          >
                            Time slot
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-gray-500"
                          >
                            Customer name
                          </th>

                          <th
                            scope="col"
                            className="py-3.5 text-sm font-normal text-gray-500  pl-5"
                          >
                            Group size
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-gray-500"
                          >
                            Category
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-gray-500"
                          >
                            Package
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-gray-500"
                          >
                            Total amount
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-gray-500"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      {currentBookings2 && currentBookings2.length > 0 && (
                        <tbody className="w-full bg-white divide-y ">
                          {currentBookings2.map((booking, index) => (
                            <tr
                              key={index}
                              className="border-b w-80percent hover:bg-gray-100 cursor-pointer"
                              onClick={() =>
                                fetchBookingDetails(booking.reservation_code)
                              }
                            >
                              <td className="px-4 py-3 text-xs text-gray-900">
                                {booking.bookingPackage ===
                                "General Reservations" ? (
                                  booking.bookingSlotStartTime
                                ) : (
                                  <>
                                    {booking.bookingSlotStartTime} -{" "}
                                    {booking.bookingSlotEndTime}
                                  </>
                                )}
                              </td>
                              <td className="px-4 py-3 text-xs text-gray-900">
                                {`${booking.bookerFirstName} ${booking.bookerLastName}`.slice(
                                  0,
                                  8
                                )}
                                {`${booking.bookerFirstName} ${booking.bookerLastName}`
                                  .length > 8 && "..."}
                              </td>

                              <td className=" py-3 text-xs text-gray-900 text-center">
                                {booking.bookingTotalGuestresult}
                              </td>
                              <td className="px-4 py-3 text-xs text-gray-900">
                                {booking.bookingPackageCategory.slice(0, 8)}
                                {booking.bookingPackageCategory.length > 8 &&
                                  "..."}
                              </td>

                              <td className="px-4 py-3 text-xs text-gray-900">
                                {booking.bookingGrouplePackageCategory}
                              </td>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-normal text-gray-900">
                                {booking.bookingPackage ===
                                "General Reservations" ? (
                                  "-"
                                ) : (
                                  <>
                                    {booking.bookingCurrency}{" "}
                                    {booking.bookingTotalCost &&
                                      Math.floor(
                                        booking.bookingTotalCost
                                      ).toLocaleString()}
                                  </>
                                )}
                              </td>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-normal">
                                {}

                                {booking.record_status === 0 ? (
                                  <div
                                    className="bg-pendingBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#FF9800"
                                      />
                                    </svg>
                                    <span className="text-pendingText">
                                      Pending
                                    </span>
                                  </div>
                                ) : booking.record_status === 1 ||
                                  booking.record_status === 5 ? (
                                  <div
                                    className="bg-approvedBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#009600"
                                      />
                                    </svg>
                                    <span className="text-approvedText">
                                      Upcoming
                                    </span>
                                  </div>
                                ) : booking.record_status === 2 ? (
                                  <div
                                    className="bg-cancelledBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#F44336"
                                      />
                                    </svg>
                                    <span className="text-cancelledText">
                                      Cancelled
                                    </span>
                                  </div>
                                ) : booking.record_status === 3 ? (
                                  <div
                                    className="bg-checkOutBG w-32 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#077E8C"
                                      />
                                    </svg>
                                    <span className="text-checkOutText">
                                      Completed
                                    </span>
                                  </div>
                                ) : booking.record_status === 4 ? (
                                  <div
                                    className="bg-checkInBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#7389DE"
                                      />
                                    </svg>
                                    <span className="text-checkInText">
                                      Ongoing
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    className="bg-pendingBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#FF9800"
                                      />
                                    </svg>
                                    <span className="text-pendingText">
                                      Pending
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  )}
                </div>
              </div>
            </div>

            {/* Pagination */}

            <div className="flex items-center  justify-between px-4 py-2 sm:px-6  bg-white border-t-0 border-gray-300 border mb-10 rounded-br-xl rounded-bl-xl h-fit">
              <div className="flex justify-between  sm:hidden">
                <a
                  href="/"
                  className="relative inline-flex items-center rounded-md  bg-white px-4 py-2 text-sm font-medium text-gray-700 "
                >
                  Previous
                </a>
                <a
                  href="/"
                  className="relative ml-3 inline-flex items-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 "
                >
                  Next
                </a>
              </div>

              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="mr-4">
                  <p className="text-sm text-gray-700">
                    <span>
                      Showing{" "}
                      {Math.min(
                        (currentPage - 1) * bookingsPerPage + 1,
                        filteredBookings2.length
                      )}{" "}
                      to{" "}
                      {Math.min(
                        currentPage * bookingsPerPage,
                        filteredBookings2.length
                      )}{" "}
                      of <span>{filteredBookings2.length}</span> bookings
                    </span>
                  </p>
                </div>

                {filteredBookings2 && filteredBookings2.length > 10 && (
                  <div>
                    <nav
                      className="isolate inline-flex gap-2 -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      <button
                        onClick={() =>
                          setCurrentPage(
                            currentPage === 1 ? 1 : currentPage - 1
                          )
                        }
                        className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-900  focus:z-20 focus:outline-offset-0 ${
                          currentPage === 1
                            ? "bg-white"
                            : "hover:bg-gray-50 border-none"
                        }`}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>

                      {currentPage > 2 && (
                        <button
                          onClick={() => setCurrentPage(1)}
                          className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 ${
                            currentPage === 1
                              ? "bg-groupleBlue text-white"
                              : "border-none "
                          }`}
                        >
                          1
                        </button>
                      )}

                      {currentPage > 4 && (
                        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900  focus:outline-offset-0">
                          ...
                        </span>
                      )}

                      {currentPage > 1 && (
                        <button
                          onClick={() => setCurrentPage(currentPage - 1)}
                          className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 ${
                            currentPage === currentPage - 1
                              ? "bg-groupleBlue text-white"
                              : "border-none hover:bg-gray-50"
                          }`}
                        >
                          {currentPage - 1}
                        </button>
                      )}

                      <button
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 bg-groupleBlue text-white`}
                      >
                        {currentPage}
                      </button>

                      {currentPage <
                        Math.ceil(
                          filteredBookings2.length / bookingsPerPage
                        ) && (
                        <button
                          onClick={() => setCurrentPage(currentPage + 1)}
                          className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 ${
                            currentPage === currentPage + 1
                              ? "bg-groupleBlue text-white"
                              : "border-none hover:bg-gray-50"
                          }`}
                        >
                          {currentPage + 1}
                        </button>
                      )}

                      {currentPage <
                        Math.ceil(filteredBookings2.length / bookingsPerPage) -
                          2 && (
                        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900  focus:outline-offset-0">
                          ...
                        </span>
                      )}

                      {currentPage <
                        Math.ceil(filteredBookings2.length / bookingsPerPage) -
                          1 && (
                        <button
                          onClick={() =>
                            setCurrentPage(
                              Math.ceil(
                                filteredBookings2.length / bookingsPerPage
                              )
                            )
                          }
                          className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 ${
                            currentPage ===
                            Math.ceil(
                              filteredBookings2.length / bookingsPerPage
                            )
                              ? "bg-groupleBlue text-white"
                              : "border-none hover:bg-gray-50"
                          }`}
                        >
                          {Math.ceil(
                            filteredBookings2.length / bookingsPerPage
                          )}
                        </button>
                      )}

                      <button
                        onClick={() =>
                          setCurrentPage(
                            currentPage ===
                              Math.ceil(
                                filteredBookings2.length / bookingsPerPage
                              )
                              ? Math.ceil(
                                  filteredBookings2.length / bookingsPerPage
                                )
                              : currentPage + 1
                          )
                        }
                        className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-900 focus:z-20 focus:outline-offset-0 ${
                          currentPage ===
                          Math.ceil(filteredBookings2.length / bookingsPerPage)
                            ? "bg-white"
                            : "hover:bg-gray-50 border-none"
                        }`}
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4 w-2/6 h-fit gap-8">
            <img src={current} alt="" />
            <div>
              <TrafficChart />
            </div>
          </div>
        </div>
      </div>

      {/* {/ SlideOver /} */}
      {selectedBooking && (
        <SlideOver
          booking={selectedBooking}
          onClose={() => setSelectedBooking(null)}
          setOpen={setOpen}
          open={open}
          currentSection={"dashboard"}
          fetchBookings={fetchBookings}
          selectedSPOCFromBooking={selectedSpoc2}
        />
      )}
    </>
  );
};

export default Dashboard;
