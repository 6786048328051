// TableRow.js
import React from "react";

const TableRow = ({ person, onRowClick }) => {
  const renderTimeSlot = () => {
    if (person.bookingPackage === "General Reservations") {
      return person.bookingSlotStartTime;
    } else {
      return (
        <>
          {person.bookingSlotStartTime} - {person.bookingSlotEndTime}
        </>
      );
    }
  };

  const renderStatus = () => {
    switch (person.record_status) {
      case 0:
        return (
          <div
            className="bg-pendingBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#FF9800" />
            </svg>
            <span className="text-pendingText">Pending</span>
          </div>
        );
      case 1:
        return (
          <div
            className="bg-approvedBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#009600" />
            </svg>
            <span className="text-approvedText">Upcoming</span>
          </div>
        );
      case 5:
        return (
          <div
            className="bg-approvedBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#009600" />
            </svg>
            <span className="text-approvedText">Upcoming</span>
          </div>
        );
      case 2:
        return (
          <div
            className="bg-cancelledBG w-28 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#F44336" />
            </svg>
            <span className="text-cancelledText">Cancelled</span>
          </div>
        );
      case 3:
        return (
          <div
            className="bg-checkOutBG w-32 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#077E8C" />
            </svg>
            <span className="text-checkOutText">Completed</span>
          </div>
        );
      case 4:
        return (
          <div
            className="bg-checkInBG w-32 rounded-3xl py-2 pl-4 pr-3 flex gap-2"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#7389DE" />
            </svg>
            <span className="text-checkInText">Ongoing</span>
          </div>
        );

      default:
        return null;
    }
  };

  const handleRowClick = () => {
    onRowClick(person.reservation_code);
  };

  return (
    <tr
      onClick={handleRowClick}
      className="cursor-pointer text-center hover:bg-gray-100"
    >
      <td className="whitespace-nowrap py-2 pr-3 text-xs font-normal text-gray-900 sm:pl-6">
        {person.reservation_code}
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-xs font-normal text-gray-500">
        {new Date(person.bookingDate).getDate()}{" "}
        {new Date(person.bookingDate).toLocaleString("default", {
          month: "long",
        })}
      </td>

      <td className="whitespace-nowrap px-3 py-2 text-xs font-normal text-gray-500 text-left">
        {renderTimeSlot()}
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-xs font-normal text-gray-900">
        {`${person.bookerFirstName} ${person.bookerLastName}`.slice(0, 8)}
        {`${person.bookerFirstName} ${person.bookerLastName}`.length > 8 &&
          "..."}
      </td>

      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-normal  text-gray-500">
        {person.bookingTotalGuestresult}
      </td>
      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-normal text-gray-500">
        {person.bookingPackageCategory.slice(0, 8)}
        {person.bookingPackageCategory.length > 8 && "..."}
      </td>

      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-normal  text-gray-500">
        {person.bookingGrouplePackageCategory.slice(0, 8)}
        {person.bookingGrouplePackageCategory.length > 8 && "..."}
      </td>
      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-normal text-gray-900">
        {person.bookingPackage === "General Reservations" ? (
          "-"
        ) : (
          <>
            {person.bookingCurrency}{" "}
            {person.bookingTotalCost &&
              Math.floor(person.bookingTotalCost).toLocaleString()}
          </>
        )}
      </td>
      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-normal">
        {renderStatus()}
      </td>
    </tr>
  );
};

export default TableRow;
