import { useContext, createContext, useState, useEffect } from "react";
import groupleLogo from "../Images/GroupleLogo.png"
import { Disclosure } from "@headlessui/react";
import { handleApiError } from "./apiErrorHandling";
import calocoLogo from "../Images/calico logo.png"
import axios from "axios";
const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};
const SidebarContext = createContext();

export default function Sidebar({ children }) {
  const [expanded, setExpanded] = useState(true);
  const hasDropdown = children && children.length > 0;

  const estateCode2 = localStorage.getItem("estateCode")
  const [logo, setLogo] = useState("")
  const fetchLogo = async () => {
    try {
      const userDataUrl = getApiUrl(`/api/v2/outlet/getOutletInformation/${estateCode2}`);
      const userLogo = await axios.get(userDataUrl, { withCredentials: true });
      if (userLogo.status === 200) {
        setLogo(userLogo.data.data.outletLogo)
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
  fetchLogo()
  }, [estateCode2]);

  return (
    <aside className={`h-screen ${expanded ? "w-64" : "w-24"}`}>
      <nav className="h-full border-r border-customGra flex flex-col bg-white shadow-sm">
        <div
          className={`mx-auto flex justify-center ${
            expanded
              ? "pb-6 pt-5 w-10/12 border-b border-customGray"
              : "w-full px-3 py-4"
          }`}
        >
          <img
            // src={logo}
            src={calocoLogo}
            alt="logo"
            className={`${
              expanded
                ? "w-[8rem] h-[4.5rem]"
                : "w-12 h-12 rounded-lg px-1 py-3 border border-customGray"
            }`}
          />
        </div>

        <SidebarContext.Provider value={{ expanded }}>
          <ul
            className={`flex-1 pt-4 ${
              expanded ? "justify-center" : "justify-center"
            }`}
          >
            {children}
          </ul>
        </SidebarContext.Provider>
        <div
          className={`flex justify-between items-center border-t border-customGray ${
            expanded ? "px-6 py-5 " : "py-5 pr-9"
          }`}
        >
          <img
            src={groupleLogo}
            className={`overflow-hidden transition-all ${
              expanded ? "w-24" : "w-0"
            }`}
            alt=""
          />
          <button
            onClick={() => setExpanded((curr) => !curr)}
            className="p-1.5 rounded-lg border border-customGray bg-gray-50 hover:bg-gray-100"
          >
            {expanded ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
              >
                <path
                  d="M12.6875 7.8396C12.6875 7.95563 12.6414 8.06691 12.5594 8.14896C12.4773 8.23101 12.366 8.2771 12.25 8.2771H4.99352L8.18453 11.4676C8.22518 11.5082 8.25742 11.5565 8.27942 11.6096C8.30142 11.6627 8.31274 11.7196 8.31274 11.7771C8.31274 11.8346 8.30142 11.8915 8.27942 11.9446C8.25742 11.9977 8.22518 12.046 8.18453 12.0866C8.14388 12.1273 8.09563 12.1595 8.04252 12.1815C7.98941 12.2035 7.93249 12.2148 7.875 12.2148C7.81751 12.2148 7.76059 12.2035 7.70748 12.1815C7.65437 12.1595 7.60612 12.1273 7.56547 12.0866L3.62797 8.14913C3.58729 8.1085 3.55502 8.06025 3.53301 8.00714C3.51099 7.95402 3.49966 7.89709 3.49966 7.8396C3.49966 7.78211 3.51099 7.72517 3.53301 7.67206C3.55502 7.61895 3.58729 7.5707 3.62797 7.53007L7.56547 3.59257C7.60612 3.55192 7.65437 3.51968 7.70748 3.49768C7.76059 3.47568 7.81751 3.46436 7.875 3.46436C7.93249 3.46436 7.98941 3.47568 8.04252 3.49768C8.09563 3.51968 8.14388 3.55192 8.18453 3.59257C8.22518 3.63322 8.25742 3.68147 8.27942 3.73458C8.30142 3.78769 8.31274 3.84461 8.31274 3.9021C8.31274 3.95958 8.30142 4.01651 8.27942 4.06962C8.25742 4.12273 8.22518 4.17098 8.18453 4.21163L4.99352 7.4021H12.25C12.366 7.4021 12.4773 7.44819 12.5594 7.53024C12.6414 7.61229 12.6875 7.72357 12.6875 7.8396ZM2.1875 2.5896C2.07147 2.5896 1.96019 2.63569 1.87814 2.71774C1.79609 2.79979 1.75 2.91107 1.75 3.0271V12.6521C1.75 12.7681 1.79609 12.8794 1.87814 12.9615C1.96019 13.0435 2.07147 13.0896 2.1875 13.0896C2.30353 13.0896 2.41481 13.0435 2.49686 12.9615C2.57891 12.8794 2.625 12.7681 2.625 12.6521V3.0271C2.625 2.91107 2.57891 2.79979 2.49686 2.71774C2.41481 2.63569 2.30353 2.5896 2.1875 2.5896Z"
                  fill="#242426"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M1.3125 7C1.3125 6.88397 1.35859 6.77269 1.44064 6.69064C1.52269 6.60859 1.63397 6.5625 1.75 6.5625L9.00648 6.5625L5.81547 3.37203C5.77482 3.33138 5.74258 3.28313 5.72058 3.23002C5.69858 3.17691 5.68726 3.11999 5.68726 3.0625C5.68726 3.00501 5.69858 2.94809 5.72058 2.89498C5.74258 2.84187 5.77482 2.79362 5.81547 2.75297C5.85612 2.71232 5.90437 2.68008 5.95748 2.65808C6.01059 2.63608 6.06751 2.62476 6.125 2.62476C6.18249 2.62476 6.23941 2.63608 6.29252 2.65808C6.34563 2.68008 6.39388 2.71232 6.43453 2.75297L10.372 6.69047C10.4127 6.7311 10.445 6.77935 10.467 6.83246C10.489 6.88558 10.5003 6.94251 10.5003 7C10.5003 7.05749 10.489 7.11442 10.467 7.16754C10.445 7.22065 10.4127 7.2689 10.372 7.30953L6.43453 11.247C6.39388 11.2877 6.34563 11.3199 6.29252 11.3419C6.23941 11.3639 6.18249 11.3752 6.125 11.3752C6.06751 11.3752 6.01059 11.3639 5.95748 11.3419C5.90437 11.3199 5.85612 11.2877 5.81547 11.247C5.77482 11.2064 5.74258 11.1581 5.72058 11.105C5.69858 11.0519 5.68726 10.995 5.68726 10.9375C5.68726 10.88 5.69858 10.8231 5.72058 10.77C5.74258 10.7169 5.77482 10.6686 5.81547 10.628L9.00648 7.4375L1.75 7.4375C1.63397 7.4375 1.52269 7.39141 1.44064 7.30936C1.35859 7.22731 1.3125 7.11603 1.3125 7ZM11.8125 12.25C11.9285 12.25 12.0398 12.2039 12.1219 12.1219C12.2039 12.0398 12.25 11.9285 12.25 11.8125L12.25 2.1875C12.25 2.07147 12.2039 1.96019 12.1219 1.87814C12.0398 1.79609 11.9285 1.75 11.8125 1.75C11.6965 1.75 11.5852 1.79609 11.5031 1.87814C11.4211 1.96019 11.375 2.07147 11.375 2.1875L11.375 11.8125C11.375 11.9285 11.4211 12.0398 11.5031 12.1219C11.5852 12.2039 11.6965 12.25 11.8125 12.25Z"
                  fill="#242426"
                />
              </svg>
            )}
          </button>
        </div>
      </nav>
    </aside>
  );
}

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

export function SidebarItem({
  iconActive,
  iconInactive,
  text,
  isActive,
  onClick,
  children,
  additionalElement,
}) {
  const { expanded } = useContext(SidebarContext);
  const [showDropdown, setShowDropdown] = useState(false);

  const hasDropdown = children && children.length > 0;

  const handleDropdownToggle = () => {
    setShowDropdown((prevState) => !prevState);
  };



  return (
    <li
      onClick={onClick}
      className={`
    relative flex w-full justify-center items-center py-3 font-medium text-base cursor-pointer 
    transition-colors group 
    ${
      isActive
        ? "bg-customLilac text-groupleBlue border-l-4 border-groupleBlue pl-5 rounded-l-sm"
        : "text-gray-600 pl-6"
    }
    ${expanded ? "" : "pl-0 pr-7"}
  `}
    >
      {isActive ? iconActive : iconInactive}
      <span
        className={`slidebarName overflow-hidden transition-all ${
          expanded ? "w-52 ml-5" : "w-0 hidden"
        }`}
      >
        {text}
      </span>

      <span className={` ${expanded ? "mr-5" : "mr-0 hidden"}`}>
        {additionalElement}
      </span>

      {hasDropdown && (
        <div className="relative">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  onClick={handleDropdownToggle}
                  className={`
                    flex rounded-md text-sm
                    ${expanded ? "w-full" : "w-14"}
                    ${open && expanded ? "" : "hover:bg-gray-50"}
                  `}
                >
                  <span
                    className={`overflow-hidden transition-all no-underline  ${
                      open && expanded
                        ? "w-full no-underline"
                        : "w-0 no-underline"
                    }`}
                  >
                    {text}
                  </span>
                  {open ? "▲" : "▼"}
                </Disclosure.Button>
                <Disclosure.Panel
                  className={`
                    mt-1 px-2
                    ${open && expanded ? "block" : "hidden"}
                  `}
                >
                  {children.map((child, index) => (
                    <div key={index} className="py-1">
                      {child}
                    </div>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      )}
    </li>
  );
}
